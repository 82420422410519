.lnb-file-upload-flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  background-color: #f9f9f9;
  max-width: 400px;

  > * { // This applies to all direct children of .c-single-file-uploader
    flex: 1;
    width: 100%; // Make each child take full width of the container
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  progress {
    width: 100%;
    height: 20px;
    -webkit-appearance: none;
    appearance: none;
    flex: 0 0 auto;

    // Custom styles for the progress bar
    &::-webkit-progress-bar {
      background-color: #eee;
      border-radius: 10px;
    }

    &::-webkit-progress-value {
      background-color: #4caf50;
      border-radius: 10px;
    }

    // Styles for other browsers like Firefox
    &::-moz-progress-bar {
      background-color: #4caf50;
      border-radius: 10px;
    }
  }
}

@keyframes stripeMove {
  0% { background-position: 0 0; }
  100% { background-position: 40px 0; }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: .5; }
}

.combined-progress {
  width: 100%;
  height: 20px;
  background: repeating-linear-gradient(
                  45deg,
                  #4caf50,
                  #4caf50 10px,
                  #8bc34a 10px,
                  #8bc34a 20px
  );
  animation: stripeMove 1s linear infinite, pulse 1.5s ease-in-out infinite;
}