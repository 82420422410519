.map {
    height: 100%;
    width: 100%;
    position: relative;
    .ol-zoom {
        top: $map_btn_margin;
        left: $map_btn_margin;
    }

    &:-moz-full-screen {
        height: 100%;
    }
    &:-webkit-full-screen {
        height: 100%;
    }
    &:-ms-fullscreen {
        height: 100%;
    }
    &:fullscreen {
        height: 100%;
    }

    .ol-rotate {
        top: auto;
        bottom: $map_btn_size * 3 + $map_btn_margin + $map_btn_small_margin * 3;
        right: $map_btn_margin;
    }

    .measure-length {
        top: auto;
        bottom: $map_btn_size * 2 + $map_btn_margin + $map_btn_small_margin * 2;
        right: $map_btn_margin;
    }

    .measure-area {
        top: auto;
        bottom: $map_btn_size + $map_btn_margin + $map_btn_small_margin;
        right: $map_btn_margin;
    }

    .ol-control {
        background-color: rgba(#eee,0.5);
        border-radius: 2px !important;
        padding: 1px !important;
        z-index: 20;

        button {
            background-color: rgba($map_base_color, .6) !important;
            cursor: pointer;
            border-radius: 1px !important;
            height: $map_btn_size;
            width: $map_btn_size;
            outline-width: 0px !important;
            &.active {
                background-color: rgba($map_base_color, 1.0) !important;
            }
        }

        button:focus, button:hover {
            text-decoration: none;
            background-color: rgba($map_base_color, .8) !important;
            color: white !important;
            outline-width: 0px !important;
            &.active {
                background-color: rgba($map_base_color, 1.0) !important;
            }
        }
    }

    .map-tooltips {
        position: relative;
        padding: 2px;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        opacity: 0.8;
        white-space: nowrap;
        font-size: 0.7rem;
        font-variant: normal;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
    }
    .ol-scale-line {
        background: rgba($map_base_color, 0.5);
        border-radius: 2px;
    }
    .ol-scale-line:hover {
        background: rgba($map_base_color, 0.9);
    }
    .ol-attribution {
        background-color: rgba(#eee,0.8) !important;
        bottom: $map_btn_margin !important;
        right: $map_btn_margin * 2 + $map_btn_size;
        button {
            width: auto;
            min-width: $map_btn_size;
            padding: 3px;
            span {
                font-size: 0.7rem;
            }
        }
        ul {
            font-size: 0.8em;
            li {
                display: block;
            }
        }
    }

    .ol-full-screen {
        top: auto;
        bottom: $map_btn_margin !important;
        right: $map_btn_margin;
        font-size: 1.5em;
    }

    .map-popups {
        color: #333333;
        position: absolute;
        background-color: white;
        -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
        filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
        padding: 5px;
        border-radius: 1px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        min-width: 450px;
        z-index: 15;
    }
    .map-popups:after, .map-popups:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .map-popups:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
    }
    .map-popups:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }
    .map-popup-close-btn {
        position: absolute;
        cursor: pointer;
        top: 3px;
        right: 3px;
        color: #666;
    }
    .spinner {
        position: absolute;
        color: #fff;
        top: $map_btn_margin;
        right: $map_btn_margin + $map_side_menu_width;
        &:before {
            height: $map_btn_size;
            width: $map_btn_size;
        }
    }
}

.layer-switcher {
    position: initial !important;
    font-size: 0.8rem;
    padding-right: 5px;
    ul {
        margin: 5px 0px;
    }
    button:focus, button:hover {
        outline: 0px;
    }
}

.map-search {
    top: $map_btn_margin;
    right: $map_btn_size + $map_btn_margin * 2;
    width: $map_side_menu_width - ($map_btn_size + $map_btn_margin);
    font-size: 0.78rem;

    input, button, select, textarea {
        background-color: rgba(#fff,0.9);
        height: $map_btn_size;
        font-size: 0.78rem;
    }

    .dropdown-item, .dropdown-header {
        padding: 5px 10px !important;
        font-size: 0.78rem  !important;
    }

    .dropdown-header {
        font-variant: small-caps;
        font-weight: bold;
        background: rgba($map_base_color, .6);
        color: white;
    }

    .show-more{
        text-decoration: underline;
        text-align: center;
        width: 100%;
        cursor: pointer;
        margin: 0px;
    }

    .rbt-aux {
        .rbt-close {
            margin: 0px;
        }
    }

}

.measure-static-tooltip {
    background-color: $map_base_color;
    border-radius: 1px;
    color: white;
    padding: 5px;
    font-size: 0.9rem;
}

.measure-dynamic-tooltip {
    @extend .measure-static-tooltip;
    font-size: 0.7rem;
}