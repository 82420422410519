@import "map/variables";
@import "map/accordion";
@import "map/map";
@import "map/side-menu";

.map-app-container{
    height: 100%;
    width: 100%;
    font-size: 1rem;
    input, button, select, textarea, p {
        font-family: 'Open Sans', sans-serif !important;
    }
    .feature-info {
        table, .open-link{
            font-size: 0.8em;
        }
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 0px 10px 0px 0px;
        .ir-derigs-badge-area {
            display: inline;
            float: right;
            margin: 2px;
            font-size: 80%;
        }
    }
}