.react-tagsinput-tag {
    background-color: $primary;
    border-radius: 2px;
    border: 1px solid $primary;
    color: $white;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
}

.form-group {
    margin-bottom: 0.5rem;
}

.links {
    margin-bottom: 0.2rem;
}

.no-wrap {
    white-space : nowrap;
}

.inline-links {
    margin-bottom: 0.2rem;
    display: inline;
}

.multiple-values {
    margin-right: 0.8rem;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .actionButtons {
        flex: 0 0  auto;
        width: 100%;
    }
    form {
        flex: 1 1 auto;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 5px;
    }
}

.form-pane-scrollable{
    @extend .data-panel;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    form {
        padding: 5px;
    }
    .actionButtons {
        flex: 0 0  auto;
        width: 100%;
    }
}

.form-wrapper-pane {
    @extend .form-wrapper;
    @extend .data-panel;
}

.form-check-input {
    margin-left: 0 !important;
    position: relative;
}

  .react-datepicker-popper {
    z-index: 10;
}

  .bold {
      font-weight: bold;
  }

  .italic {
    font-style: italic;
}

.cross-out {
    text-decoration: line-through;
}

.display-linebreak {
    white-space: pre-line;
}

.red {
    color: red !important;
}

.green {
    color: $blue !important;
}

.no-margin {
    margin-bottom: 0px;
}

.card-header{
    font-weight: bold;
}

.field-label {
    font-style: italic;
}

.rbt-input-main::-webkit-input-placeholder , .form-control::-webkit-input-placeholder { color: $gray-400 !important; font-style: italic !important;}  /* WebKit, Blink, Edge */
.rbt-input-main:-moz-placeholder, .form-control:-moz-placeholder { color: $gray-400 !important; font-style: italic !important;}  /* Mozilla Firefox 4 to 18 */
.rbt-input-main::-moz-placeholder , .form-control::-moz-placeholder { color: $gray-400 !important; font-style: italic !important;}  /* Mozilla Firefox 19+ */
.rbt-input-main:-ms-input-placeholder, .form-control:-ms-input-placeholder { color: $gray-400 !important; font-style: italic !important;}  /* Internet Explorer 10-11 */
.rbt-input-main::-ms-input-placeholder, .form-control::-ms-input-placeholder { color: $gray-400 !important; font-style: italic !important;}  /* Microsoft Edge */

.form-control {
    padding: 0.375rem, 0.375rem !important;
}

.notes {
    font-size: 85%;
    font-style: italic;
}

.card-body {
    padding: 5px !important;
}

.card-header {
    padding: 0.4rem 0.8rem;
    cursor: pointer;
    background-color: rgba(theme-color("primary"), .8) !important;
    color: #fefefe;
}

.card-header-action {
    cursor: pointer;
    color: #fefefe;
}

.multifield-form-row{
    .form-col{
        max-height: 400px;
        display: flex;
        flex-direction: column;
    }
}

.fullscreen-mode {
    position: fixed;
    padding:0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-width: 100% !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 3000;
    .form-col{
        max-height: unset !important;
    }
    .card{
        margin:0px;
    }
}

.saistitas {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 3px;
    margin-bottom: 8px;
}

.dropdown-menu {
    z-index: 1051;
}

.id_renderer {
    font-weight: bolder;
    color: $lvgmc_color;
}

@include media-breakpoint-down(sm) {
    .formlabel-col {
        padding-left: 10px !important;
    }
    .form-col {
        padding-left: 25px !important;
    }
}

