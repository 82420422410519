.side-menu {
    top: $map_btn_size + $map_btn_margin * 2;
    right: $map_btn_margin;
    max-height: calc(100% - #{$map_btn_size * 4 + $map_btn_margin * 4});
    overflow-x: auto;
    width: $map_side_menu_width;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    background-color: rgba(#eee,0.5);
    border-radius: 2px !important;
    padding: 1px !important;
    z-index: 5;
    position: absolute;
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
}

.hidden {
    opacity: 0 !important;
    visibility: hidden !important;
}

.side-menu-btn {
    top: $map_btn_margin;
    right: $map_btn_margin;
}
