// Here you can add other styles

.pointer {
  cursor: pointer;
}

.plus-button {
  cursor: pointer;
  margin-left: 3px;
  color: $gray-400;
}

.expand-collapse-button {
  cursor: pointer;
  color: $primary;
}

.expand-collapse-button-row {
  @extend .expand-collapse-button;
  margin: 5px 5px 5px 5px;
}

.flex-grow {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.dropdown-item .html-inside i {
  margin: 0;
  width: initial;
  display: initial;
  color: inherit;
  text-align: inherit;
}

.draftjs-editor-container {
  margin-bottom: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
}

.draftjs-toolbar-container > div {
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  margin-bottom: 10px;
  overflow: hidden;
}


.draftjs-toolbar-container button {
  background: #efefef;
  border: 1px solid #efefef;
  cursor: pointer;
}

.draftjs-toolbar-container button:hover {
  border: 1px solid #ccc;
  color: #444;
}

.draftjs-toolbar-container button[class*="active"] {
  background: #ccc !important;
  border: 1px solid #ccc;
}

.morfo-sub-table {
  display: flex;
  .layer {
    padding: 4px;
    width: 110px;
    font-style: italic;
  }
  .value{
    padding: 2px;
    width: 50px;
    &:empty {
      min-height: 24.5px;
    }
  }
}
td {
  .morfo-sub-table {
    &:not(:first-child) {
      .layer {
        border-top: 1px solid $gray-400;
      }

      .value {
        border-top: 1px solid $gray-400;
      }
    }
  }
}

/* removes spinner for number field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}

.form-control.is-invalid {
  padding-right: 0.375rem !important;
  background-image: none !important;
}

label {
  display: inline-block;
  margin-bottom: 0px;
}

.attach-filename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attach-fileselect {
  max-width: 85px;
  button {
    width: auto;
    i {
      margin-right: 5px;
    }
  }
}

.rbt-menu > li a:hover {
  overflow-x: auto !important;
  text-overflow: unset;
}

.rbt-input-multi {
   height: auto !important;
}

// BAIS specifics

.uo-gads {
  flex: inherit;
  min-height: 3em;
}


