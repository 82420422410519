.navbar {
  flex-wrap: nowrap !important;
  justify-content: space-between;
}

.navbar-brand {
  flex: 0 0 50px;
}

.breadcrumbs{
  flex: 1 1 40%;
  max-height: $navbar-height;
  overflow: hidden;
}

.atradne-search {
  padding: 5px;
  flex: 0 1 250px;
}

.atradne-search-sidebar {
  padding: 5px;
}

.navbar-nav{
  flex: 0 0 auto;
}

.atradne-title {
  display: flex;
  min-width: 0;
  min-height: 0;
  flex: 1 1 40%;
  overflow-x: hidden;
  h3, h4 {
    /*display: table-cell;*/
    margin: 0px 5px 0px 5px !important;
    font-size: 120%;
    padding: 5px;
    display: flex;
    align-items: center;
    .fa {
      padding: 0px 8px 0px 8px !important;
      cursor: pointer;
      color: $gray-300;
      flex: 0 0 5px;
    }
    &.atradne-num {
      flex: 0 0 130px;
      span {
        flex: 1;
        text-align: center;
      }
      .outer-span {
        display: flex;
        flex-direction: column;
      }
      &.next-prev-box {
        .fa {
          line-height: 1.5em;
        }
        form {
          display: block;
          padding: 0;
          flex: 1;
          margin: 0;
          overflow: visible;
          height: auto;
        }
        input {
          vertical-align: middle;
          text-align: center;
        }
      }
    }
    &.num-periods {
      flex: 0 0 300px;
      span {
        flex: 1;
        text-align: center;
      }
    }
    &.atradne-name {
      flex: 1 1 50%;
      min-width: 0;
      min-height: 0;
      span {
        flex: 1 1 auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        min-width: 0;
        min-height: 0;
      }
    }
  }
}

.breadcrumb {
  padding: 0px 5px 0px 5px !important;
}

.help-btn {
  margin-right: 8px;
}
