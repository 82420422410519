.splitter-layout {
  position: unset !important;
}

.splitter-layout .layout-pane.layout-pane-primary {
  display: flex;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
  height: 6px;
  @include media-breakpoint-down(sm) {
    height: 10px;
  }
}

.splitter-layout > .layout-splitter {
  width: 6px;
  @include media-breakpoint-down(sm) {
    width: 10px;
  }
  margin: 2px;
  border: 1px outset #eee;
  border-radius: 3px;
}

.pane-paddings-hor {
  .layout-pane {
    padding: 0px 5px 0px 5px;
  }
}
