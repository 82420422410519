.leftside {
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding-top: 12px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .tab-content {
        flex: 2;
        width: 100%;
        height: calc(100% - 50px);
        .tab-pane {
            overflow: hidden;
            height: 100%;
            width: 100%;
            position: relative;
        }
        border-top-width: 0px;
        border-radius: 0px;
    }
    .nav-tabs {
        flex: 0;
        height: 50px;
    }
    .nav-link {
        display: block;
        padding: 0.2rem 0.6rem;
    }
}

.topside {
    @extend .leftside;
    padding-top: 0px;
    .nav-tabs{
        margin-top: 12px;
    }
}

.form-container {
    @extend .form-wrapper;
    .actionButtons .btn, .actionButtons .field {
        margin-top: 2px;
    }
}

.form-with-grid-container {
    @extend .form-container;
    .react-bootstrap-table {
        flex: 1 1 auto;
        overflow-y: auto;
        width: 100%;
    }
    form, .form-additional {
        flex: 0 0 auto;
        height: auto;
        width: 100%;
        padding: 5px;
        overflow-y: unset;
        overflow-x: unset;
    }
    .form-additional {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.form-with-grid-container-scrollable {
    @extend .form-with-grid-container;
    overflow-y: scroll;
}

.form-or-grid-container {
    @extend .form-container;
    .react-bootstrap-table {
        flex: 1 1 auto;
        overflow-y: auto;
        width: 100%;
    }
    form {
        flex: 0 1 auto;
        height: auto;
        width: 100%;
        overflow-y: auto;
    }
}

.full-height-form-or-grid-container {
    @extend .form-container;
    .react-bootstrap-table, form {
        flex: 1 1 auto;
        overflow-y: hidden;
        &.react-bootstrap-table {
            overflow-y: auto !important;
        }
        width: 100%;
        &.hidden {
            display: none;
        }
        .form-group {
            height: 100%;
            .form-col {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.leftside-single {
    @extend .leftside;
    padding-top: 0px;
    .nav-tabs{
        margin-top: 12px;
    }
}

.rightside {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .actionButtons {
        flex: 0 0  auto;
        width: 100%;
    }
    .karte-wrapper {
        flex: 1 1 auto;
        height: 100%;
        width: 100%;
        overflow: hidden;
        @extend .data-panel;
    }
}

.grids-with-titles{
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    .grid-pane {
        margin-top: 5px;
        width: 100%;
        @extend .data-panel;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        align-items: stretch;
        .title-bar {
            flex: 0 0 auto;
            width: 100%;
            button {
                margin-bottom: 5px;
            }
            h4 {
                display: inline-block;
            }
        }
        .react-bootstrap-table {
            flex: 1 1 auto;
            width: 100%;
        }
    }
}

.vesture-diffs thead th {
    background-color: $gray-300;
    z-index: 9;
}