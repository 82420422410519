.layer-switcher {
  position: absolute;
  top: 3.5em;
  right: 0.5em;
  text-align: left;
}

.layer-switcher .panel {
  margin: 0;
  border: 4px solid #eee;
  border-radius: 4px;
  background-color: white;
  display: none;
  max-height: inherit;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.layer-switcher button {
  float: right;
  z-index: 1;
  width: 38px;
  height: 38px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==')
    /*logo.png*/;
  background-repeat: no-repeat;
  background-position: 2px;
  background-color: white;
  color: black;
  border: none;
}

.layer-switcher button:focus,
.layer-switcher button:hover {
  background-color: white;
}
.layer-switcher.shown {
  overflow-y: hidden;
}
.layer-switcher.shown.ol-control {
  background-color: transparent;
}

.layer-switcher.shown.ol-control:hover {
  background-color: transparent;
}
.layer-switcher.shown .panel {
  display: block;
}

.layer-switcher.shown button {
  display: none;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  display: block;
  background-image: unset;
  right: 2px;
  position: absolute;
  background-color: #eee;
  margin: 0 1px;
}

.layer-switcher.shown button:focus,
.layer-switcher.shown button:hover {
  background-color: #fafafa;
}

.layer-switcher ul {
  list-style: none;
  margin: 1.6em 0.4em;
  padding-left: 0;
}
.layer-switcher ul ul {
  padding-left: 1.2em;
  margin: 0.1em 0 0 0;
}
.layer-switcher li.group + li.group {
  margin-top: 0.4em;
}
.layer-switcher li.group + li.layer-switcher-base-group {
}

.layer-switcher li.group > label {
  font-weight: bold;
}

.layer-switcher.layer-switcher-group-select-style-none li.group > label {
  padding-left: 1.2em;
}

.layer-switcher li {
  position: relative;
  margin-top: 0.3em;
}

.layer-switcher li input {
  position: absolute;
  left: 1.2em;
  height: 1em;
  width: 1em;
  font-size: 1em;
}

.layer-switcher li label {
  padding-left: 2.7em;
  padding-right: 1.2em;
  display: inline-block;
  margin-top: 1px;
}

.layer-switcher label.disabled {
  opacity: 0.4;
}

.layer-switcher input {
  margin: 0px;
}

.layer-switcher.touch ::-webkit-scrollbar {
  width: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.layer-switcher.touch ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

li.layer-switcher-base-group > label {
  padding-left: 1.2em;
}

.layer-switcher .group button {
  position: absolute;
  left: 0;
  display: inline-block;
  vertical-align: top;
  float: none;
  font-size: 1em;
  width: 1em;
  height: 1em;
  margin: 0;
  background-position: center 2px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==');
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}

.layer-switcher .layer button {
  left: auto;
  right: 2px;
  width: 14px;
  height: 14px;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAXdJREFUOE+lU71KA0EQ/mY3MViICipqWkFBLBQf4Ex2CXkAS6t0+gBJo4WN2osWio2+QzhuD+8BRAsRLGwEUVBQwUJiuIzscQmniRi4qWbmG77db34If1i5XB5vNpvPFs5msxP1ev2lVym1k47jjORyuUHXdZ9sTinlADiP8RVjTGD9Uqk01Wg0PoMgeLdxRKCUGgbgAphl5qrv+0da63Vm3rc4M2/4vn+gtV5l5kMA91JK7brua0RgWcMwvAEwGr94BWAGwFAcfwC4A7AYx29Synn7246EYrFYIaLjv3ryK18xxpx0JMQgKaUuACwx8xeATSHEWYyttVqtbSIaAHBpjFm2yiICx3HGpJQLRDQHYM9+m4hqnudZv2Na6yoz7wKwcqx/G4bhtX01YkqalHK6PY3ElCYzmUw0oaT1JCCivOd5j8nCQqGQF0I8dBH0K0EpVQOw0yUhwfijiUKILQCn/zaxTZBqjKkXKfUqWxmpjqnX+vZ7zt8ni9pPk1VvWQAAAABJRU5ErkJggg==');
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}


.layer-switcher .group.layer-switcher-close button {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.layer-switcher .group.layer-switcher-fold.layer-switcher-close > ul {
  overflow: hidden;
  height: 0;
}

.layer-switcher .layer .layer-switcher-conf-close {
  display: none;
}

.layer-switcher .layer .layer-switcher-conf-open {
  min-height: 10px;
}

.layer-switcher .layer .layer-switcher-conf-open div {
  margin: 5px 5px 5px 20px;
  min-height: 10px;
}

.layer-switcher .layer .layer-switcher-conf-open input.opacity {
  width: 80px;
  margin-left: 10px;
  position: relative;
  left: 0px;
}

.layer-switcher .layer .layer-switcher-conf-open label {
  padding: 0px;
}

.layer-switcher .layer .layer-switcher-conf-open .legend p{
  margin: 0px;
  font-size: 90%;
}

/*layerswitcher on the right*/
.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-left: 34px;
}
.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  left: 0;
  border-right: 0;
}

/*layerswitcher on the left*/
/*
.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-right: 34px;
}
.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  right: 0;
  border-left: 0;
}
*/
