.c-single-file-uploader {

    .csfu-droparea {
        width: 100%;
        border: 0;
        text-align: center;
        padding: 0.5rem;
        background: #f3f3f3;
        color: #555;
    }

    .csfu-droparea .fa {
        display: block;
        font-size: 130%;
        margin-bottom: 0.7rem;
    }
    .csfu-droparea:hover {
        background: #eee;
    }

    .csfu-droparea-inner {
        border: 2px transparent;
        padding: 0.7rem;
    }

    .csfu-droparea-inner.is-dragging {
        border-color: #ccc;
    }

    .csfu-droparea {
        width: 100%;
        border: 0;
    }
}

.btn-outline-download {
    // normālie outline-* pārāk acīs lecoši
    border-color: #ddd;
    color: #5c6873;
    .fa {
        margin-right: 0.5rem;
    }
}


.btn-outline-download:hover {
    background-color: #f3f3f3;
}
