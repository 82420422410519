.files-container {
  display: flex;
  flex-direction: column;
}

.file-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px; // Adjust the space between rows as needed

  .file-info, .file-actions {
    flex: 1; // This will make both divs take up equal space
    display: flex;
    align-items: center;

    .file-label {
      margin-right: 10px; // Adjust the space between label and content
    }
  }
}